import { ReactNode } from 'react'
import { atom, selector } from 'recoil'

type User = {
  userId: string
  personId: string
  email: string
  role: 'Canddiate' | 'Client'
  expiration: number
}

type ModalContent = {
  title?: string | ReactNode
  content: ReactNode
  showCloseButton?: boolean
}

type Snackbar = {
  message: string | null
  severity?: 'error' | 'info' | 'success' | 'warning'
}

export const getCookies = (): Record<string, string> => {
  return document.cookie.split(';').reduce((acc, curr) => {
    const [_key, value] = curr.split('=')
    const key = _key.trim()
    return { ...acc, [key]: value }
  }, {})
}

export const userState = selector<User | undefined | null>({
  key: 'userState',
  get: () => {
    const cookies = getCookies()
    if (!cookies['user']) {
      const currentPath = window.location.pathname + window.location.search
      window.location.href = `/api/login?current=${encodeURIComponent(
        currentPath,
      )}`
    }
    const tokenObject = JSON.parse(
      Buffer.from(cookies['user'], 'base64').toString('utf8'),
    )
    return {
      email: tokenObject.email,
      userId: tokenObject.userId,
      personId: tokenObject.personId,
      role: tokenObject.role,
      expiration: tokenObject.expiration,
    }
  },
})

const modalContentInternal = atom<ModalContent>({
  key: 'modalContentInternal',
  default: {
    content: null,
    showCloseButton: false,
  },
})

export const modalContentState = selector<ModalContent>({
  key: 'modalContent',
  get: ({ get }) => {
    return get(modalContentInternal)
  },
  set: ({ set }, value) => {
    set(modalOpenState, true)
    set(modalContentInternal, value)
  },
})

export const modalOpenState = atom<boolean>({
  key: 'modalOpen',
  default: false,
})

const snackbarInternal = atom<Snackbar>({
  key: 'snackbarInternal',
  default: {
    message: null,
  },
})

export const snackbarMessageState = selector<Snackbar>({
  key: 'snackbarMessage',
  get: ({ get }) => {
    return get(snackbarInternal)
  },
  set: ({ set }, value) => {
    set(snackbarInternal, value)
  },
})

const rightDrawerContentInternal = atom<DrawerContent>({
  key: 'rightDrawerContentInternal',
  default: {
    title: '',
    content: null,
  },
})

type DrawerContent = {
  title: string
  content: ReactNode
}

export const rightDrawerContent = selector<DrawerContent>({
  key: 'rightDrawerContent',
  get: ({ get }) => {
    return get(rightDrawerContentInternal)
  },
  set: ({ set }, value) => {
    set(rightDrawerOpen, true)
    set(rightDrawerContentInternal, value)
  },
})

const rightDrawerOpenInternal = atom<boolean>({
  key: 'rightDrawerOpenInternal',
  default: false,
})

export const rightDrawerOpen = selector<boolean>({
  key: 'rightDrawerOpen',
  get: ({ get }) => {
    return get(rightDrawerOpenInternal)
  },
  set: ({ set }, value) => {
    set(rightDrawerOpenInternal, value)
    set(rightDrawerContentInternal, {
      title: '',
      content: null,
    })
  },
})
