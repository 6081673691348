import styled from '@emotion/styled'
import { base } from '../styles/spacing'
import { IconButton } from './mui/IconButton'
import CloseOutlined from '@mui/icons-material/CloseOutlined'
import { ReactNode } from 'react'

type RightDrawerProps = {
  open: Boolean
  setOpen: (open: Boolean) => void
  children: ReactNode
  onClose?: () => void
}

export const RightDrawer = ({
  open,
  setOpen,
  children,
  onClose,
}: RightDrawerProps) => {
  const exitDrawer = () => {
    setOpen(false)
    if (onClose) {
      onClose()
    }
  }

  if (!open) {
    return null
  }

  return (
    <StyledRightDrawer>
      <StyledHeader>
        <IconButton style={{ marginLeft: 'auto' }} onClick={exitDrawer}>
          <CloseOutlined />
        </IconButton>
      </StyledHeader>
      <StyledContent>{children}</StyledContent>
    </StyledRightDrawer>
  )
}

const StyledRightDrawer = styled.div`
  position: fixed;
  background-color: var(--surface);
  top: 0;
  left: auto;
  right: 0;
  width: ${base * 125}px;
  height: 100%;
  box-shadow: var(--elevation-1);
  z-index: 3;
  @media (max-width: 900px) {
    width: 100%;
  }
`

const StyledHeader = styled.div`
  display: flex;
`

const StyledContent = styled.div`
  height: 90%;
  overflow-y: auto;
`
