import MUIIconButton from '@mui/material/IconButton'
import { styled } from '@mui/material/styles'
import { base } from '../../styles/spacing'

const StyledIconButton = styled(MUIIconButton)({
  color: 'var(--default-600)',
  '& svg': {
    fontSize: `${base * 6}px`,
  },
})

export const IconButton = StyledIconButton
