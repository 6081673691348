import Axios, { AxiosRequestConfig } from 'axios'
import { useState } from 'react'
import useDeepCompareEffect from 'use-deep-compare-effect'

import { Readmodels } from 'shared/readmodels/readmodels.schema'
import { BFF_ENDPOINT } from '../config'
import { useSSEWatch } from './useSSEWatch'
import { useEffect } from 'react'
import { useSetRecoilState } from 'recoil'
import { snackbarMessageState } from '../state'

const cache: any = {}

export const useReadmodel = <
  Name extends keyof Readmodels,
  Response extends Readmodels[Name]['response'] | null,
>(
  readmodel: Name,
  parameters: Readmodels[Name]['request'],
  defaultValue: Response,
  options?: {
    useCache?: boolean
    skipFetch?: boolean
    preferDefaultValue?: boolean
  },
): [
  data: Response extends null
    ? Readmodels[Name]['response'] | null
    : Readmodels[Name]['response'],
  isLoading: boolean,
] => {
  const cacheKey = options?.useCache
    ? JSON.stringify({ readmodel, parameters })
    : ''
  const [data, setData] = useState(
    options?.useCache && cache[cacheKey] ? cache[cacheKey] : defaultValue,
  )
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [watchlist, setWatchlist] = useState([])
  const setSnackbarValue = useSetRecoilState(snackbarMessageState)

  const getData = () => {
    if (!options?.skipFetch) {
      setIsLoading(true)
      const queryString = parameters
        ? `?${new URLSearchParams(parameters).toString()}`
        : ''
      Axios.get(`${BFF_ENDPOINT}/readmodel/${readmodel}${queryString}`)
        .then(({ data }: any) => {
          setData(data.data)
          setWatchlist(data.watchlist)
          setIsLoading(false)
          if (options?.useCache) {
            cache[cacheKey] = data.data
          }
        })
        .catch((err) => {
          setIsLoading(false)
          setSnackbarValue({
            message: `Error: ${err.message}`,
            severity: 'error',
          })
        })
    }
  }

  useEffect(() => {
    if (options?.preferDefaultValue) {
      setData(defaultValue)
    }
  }, [options, defaultValue])

  useDeepCompareEffect(() => getData(), [readmodel, parameters, {}])
  useSSEWatch(() => getData(), watchlist)

  return [data, isLoading]
}
