import styled from '@emotion/styled'
import { GetJobByIdResponse } from 'shared/readmodels/getJobById.schema'
import { base } from '../styles/spacing'
import { reasons } from './ReasonSelect'
import { Tag } from './Tag'

type TagsProps = {
  presentation: NonNullable<GetJobByIdResponse>['presentations'][0]
  className?: string
  onRemoveTag?: (tag: string) => void
}

export const Tags = ({ presentation, className, onRemoveTag }: TagsProps) => {
  const tags = presentation.tags

  const showNewTag = !presentation.viewed
  const showActionNeededTag = presentation.viewed && !presentation.decision

  if (
    !showNewTag &&
    !presentation.decision &&
    !tags?.length &&
    !showActionNeededTag
  ) {
    return null
  }

  return (
    <TagsWrapper className={className}>
      {showNewTag && <Tag label="New" color="bright" />}
      {showActionNeededTag && <Tag label="Action needed" color="dark" />}
      {presentation.decision === 'interested' && (
        <Tag label="Interesting" color="bright" />
      )}
      {presentation.decision === 'notInterested' &&
        presentation.reasonForDecision && (
          <Tag
            label={
              reasons[
                presentation.reasonForDecision || 'notEnoughRelevantSkills'
              ]
            }
            color="dark"
          />
        )}
      {tags?.map((tag) => (
        <Tag
          key={tag}
          label={tag}
          color="bright"
          onRemove={
            onRemoveTag &&
            (() => {
              onRemoveTag(tag)
            })
          }
        />
      ))}
    </TagsWrapper>
  )
}

const TagsWrapper = styled.div`
  display: flex;
  gap: ${base * 2}px;
  flex-wrap: wrap;
`
