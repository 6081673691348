import MUIAccordion from '@mui/material/Accordion'
import MUIAccordionSummary from '@mui/material/AccordionSummary'
import MUIAccordionDetails from '@mui/material/AccordionDetails'
import { styled } from '@mui/material/styles'
import { base } from '../../styles/spacing'

const StyledAccordion = styled(MUIAccordion)({
  '&.MuiAccordion-root': {
    boxShadow: 'none',
    background: 'none',
    '&:before': {
      display: 'none',
    },
  },
})

const StyledAccordionSummary = styled(MUIAccordionSummary)({
  '&.MuiAccordionSummary-root': {
    color: 'var(--secondary-1000)',
    fontFamily: 'Awesome',
    fontSize: 16,
    padding: 0,
    fontWeight: 700,
    display: 'flex',
    justifyContent: 'space-between',
    '& .MuiAccordionSummary-expandIconWrapper': {
      marginRight: `${base * 3}px`,
      '& svg': {
        color: 'var(--primary-1000)',
        fontSize: 24,
      },
    },
  },
})
const StyledAccordionDetails = styled(MUIAccordionDetails)({})

export const Accordion = StyledAccordion
export const AccordionSummary = StyledAccordionSummary
export const AccordionDetails = StyledAccordionDetails
