import MUITabContext from '@mui/lab/TabContext'
import MUITabList from '@mui/lab/TabList'
import MUITabs from '@mui/material/Tabs'
import MUITab from '@mui/material/Tab'
import MUITabPanel from '@mui/lab/TabPanel'
import { styled } from '@mui/material/styles'
import { base } from '../../styles/spacing'

const StyledTabContext = styled(MUITabContext)({})
const StyledTabList = styled(MUITabList)({
  '& .MuiTabs-indicator': {
    backgroundColor: 'var(--secondary-1000)',
  },
})
const StyledTabs = styled(MUITabs)({})
const StyledTab = styled(MUITab)({
  fontFamily: 'Awesome',
  fontSize: 16,
  '&.Mui-selected': {
    color: 'var(--secondary-1000)',
  },
})
const StyledTabPanel = styled(MUITabPanel)({
  padding: `${base * 4}px 0px`,
})

export const TabContext = StyledTabContext
export const TabList = StyledTabList
export const Tabs = StyledTabs
export const Tab = StyledTab
export const TabPanel = StyledTabPanel
