import styled from '@emotion/styled'
import { FC } from 'react'
import { useSetRecoilState } from 'recoil'
import { modalOpenState } from '../state'
import { base } from '../styles/spacing'
import { Button } from './mui/Button'

type ConfirmProps = {
  subText?: string
  onCancel?: () => void
  onConfirm: () => void
}

export const Confirm: FC<ConfirmProps> = ({ subText, onCancel, onConfirm }) => {
  const openModal = useSetRecoilState(modalOpenState)

  const handleClose = () => {
    openModal(false)
    onCancel && onCancel()
  }

  const handleConfirm = () => {
    onConfirm && onConfirm()
    openModal(false)
  }

  return (
    <Container>
      {subText && <p>{subText}</p>}
      <ButtonContainer>
        <Button fullWidth size="small" variant="text" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          fullWidth
          size="small"
          variant="contained"
          onClick={handleConfirm}
        >
          Confirm
        </Button>
      </ButtonContainer>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: ${base * 82}px;
  & > p {
    margin-bottom: ${base * 3}px;
  }
`

const ButtonContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: ${base * 2}px;
`
