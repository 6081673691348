import { css } from '@emotion/react'
import { useReadmodel } from '../hooks/useReadmodel'

export const ContactName = ({ email }: { email: string }) => {
  const [contactName] = useReadmodel('getContactName', { email: email }, null)

  if (!contactName) {
    return null
  }

  return (
    <h3
      className="h3"
      css={css`
        color: var(--secondary-1000);
      `}
    >
      {contactName}
    </h3>
  )
}
