import { sm } from '../styles/spacing'

export const deleteAllCookies = () => {
  const cookies = document.cookie.split(';')

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i]
    const name = cookie.indexOf('=') > -1 ? cookie.split('=')[0] : cookie
    document.cookie = name + '=;Path=/;Expires=Thu, 01 Jan 1970 00:00:00 GMT'
  }
}

export const isSmallScreen = () =>
  window && window.matchMedia(`only screen and (max-width: ${sm}px)`).matches

export const sortByDate = (a: Date | string, b: Date | string) =>
  new Date(b).getTime() - new Date(a).getTime()

export const notEmpty = <TValue>(
  value: TValue | null | undefined,
): value is TValue => {
  return value !== null && value !== undefined
}
