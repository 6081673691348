import MUIBadge from '@mui/material/Badge'
import { styled } from '@mui/material/styles'
import { base } from '../../styles/spacing'

const StyledBadge = styled(MUIBadge)({
  '& .MuiBadge-badge': {
    right: -15,
    top: 13,
    padding: `0 ${base}px`,
    backgroundColor: 'var(--accent-light)',
    color: 'var(--secondary-1000)',
  },
})

export const Badge = StyledBadge
