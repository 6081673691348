export const getDateString = (date: Date) => {
  const year = date.getFullYear()
  const month = getFormattedNumber(date.getMonth() + 1)
  const day = getFormattedNumber(date.getDate())
  const hour = getFormattedNumber(date.getHours())
  const minutes = getFormattedNumber(date.getMinutes())

  return `${year}-${month}-${day} ${hour}:${minutes}`
}

const getFormattedNumber = (n: number): string => {
  if (n > 9) {
    return n.toString()
  }
  return '0' + n.toString()
}
