import MUIButton from '@mui/material/Button'
import { styled } from '@mui/material/styles'

const StyledButton = styled(MUIButton)({
  color: 'var(--secondary-1000)',
  fontFamily: 'Awesome',
  textTransform: 'none',
  fontSize: 16,
  borderRadius: 0,
  lineHeight: '24px',
  letterSpacing: 'normal',
  '&.MuiButton': {
    '&-textSizeSmall': {
      fontSize: 16,
      '&:hover': {
        backgroundColor: 'var(--secondary-100)',
      },
    },
    '&-contained': {
      backgroundColor: 'var(--secondary-1000)',
      color: 'var(--surface)',
      boxShadow: 'none',
      '&SizeSmall': {
        fontSize: 16,
      },
      '&:hover': {
        backgroundColor: '#025449',
        boxShadow: 'none',
      },
    },
    '&-outlined': {
      borderColor: 'var(--secondary-1000)',
    },
  },
  '&.Mui-disabled': {
    backgroundColor: 'var(--default-300)',
    color: 'var(--default-400)',
  },
})

export const Button = StyledButton

const StyledDiscreetButton = styled(MUIButton)({
  color: 'var(--primary-800)',
  fontFamily: 'Awesome',
  fontSize: 16,
  textTransform: 'none',
  padding: 0,
  '&.MuiButton': {
    '&-textSizeSmall': {
      fontSize: '12px',
      padding: 0,
      '&:hover': {
        backgroundColor: 'transparent',
        color: 'var(--primary-1000)',
      },
    },
    '&-textPrimary': {
      color: 'var(--primary-800)',
    },
    '&-textSecondary': {
      color: 'var(--secondary-1000)',
    },
  },
  '&:hover': {
    backgroundColor: 'transparent',
    color: 'var(--primary-1000)',
  },
})

export const DiscreetButton = StyledDiscreetButton
