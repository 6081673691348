import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { useLocation } from 'react-router-dom'
import { SignOutButton } from '../components/SignOutButton'
import { base } from '../styles/spacing'

type ErrorTypes = 'notCorrespondingEmail' | string

const getNotCorrespondingEmail = () => (
  <>
    <h2
      className="h2"
      css={css`
        color: var(--secondary-1000);
        margin-bottom: ${base * 4}px;
      `}
    >
      The e-mail address used to login does not match an account
    </h2>
    <p>
      Please sign out and use the e-mail address where you first were invited to
      the candidate presentation platform with. Have a look in your inbox and
      search for “New candidate presented” and the e-mail should be addressed
      from Academic Work.
    </p>
    <p>If you have any questions, please contact your AW recruiter.</p>
    <div
      css={css`
        display: inline-flex;
      `}
    >
      <SignOutButton confirmSignOut={false} />
    </div>
  </>
)

const getErrorContent = (type: ErrorTypes) => {
  switch (type) {
    case 'notCorrespondingEmail':
      return getNotCorrespondingEmail()
      break
    default:
      return 'Unknown error'
      break
  }
}

export const Error = () => {
  const { search } = useLocation()
  const type = new URLSearchParams(search).get('type')

  if (!type) {
    return null
  }

  return <Wrapper>{getErrorContent(type)}</Wrapper>
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  & > p {
    margin-bottom: ${base * 4}px;
  }
`
