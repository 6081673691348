import styled from '@emotion/styled'
import { useState } from 'react'
import { GetJobByIdResponse } from 'shared/readmodels/getJobById.schema'
import { useReadmodel } from '../../hooks/useReadmodel'
import { base } from '../../styles/spacing'
import { Tab, TabContext, TabList, TabPanel } from '../mui/Tabs'
import { CollaboratorIcon } from './CollaboratorIcon'
import { CollaboratorList } from './CollaboratorList'
import { InviteCollaborator } from './InviteCollaborator'

export const Collaborators = ({
  jobId,
  mainContactId,
}: {
  jobId: NonNullable<GetJobByIdResponse>['_id']
  mainContactId: number
}) => {
  const [tabValue, setTabValue] = useState('1')
  const [collaborators] = useReadmodel('getCollaborators', { jobId }, [])

  return (
    <ContentWrapper>
      <TabContext value={tabValue}>
        <TabList
          value={tabValue}
          onChange={(_, value) => setTabValue(value)}
          variant="fullWidth"
          scrollButtons="auto"
        >
          <Tab value="1" label="Invite" />
          <Tab value="2" label="Collaborators" />
        </TabList>
        <TabPanel value="1">
          <InviteCollaborator
            jobId={jobId}
            collaborators={collaborators}
            onSubmitted={() => setTabValue('2')}
          />
        </TabPanel>
        <TabPanel value="2">
          <CollaboratorList
            jobId={jobId}
            mainContactId={mainContactId}
            collaborators={collaborators}
          />
        </TabPanel>
      </TabContext>
    </ContentWrapper>
  )
}

export const InviteCollaboratorsHeader = () => (
  <>
    <CollaboratorIcon width={72} height={72} />
    <h2 className="h2">Collaborators</h2>
  </>
)

const ContentWrapper = styled.div`
  padding: ${base * 4}px;
  width: ${base * 94}px;
  text-align: left;
`
