import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Link } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { userState } from '../state'
import { base, gutter } from '../styles/spacing'
import { AWLogo } from './AWLogo'
import { ContactName } from './ContactName'
import { SignOutButton } from './SignOutButton'
import { Button } from './mui/Button'

export const Header = ({ hideUser }: { hideUser?: boolean }) => {
  const user = useRecoilValue(userState)
  const showSignIn = user
  return (
    <StyledHeader>
      <StyledContent>
        <StyledLink
          to="/"
          css={css`
            margin-top: 5px;
            visibility: ${user?.email ? 'visible' : 'hidden'};
          `}
        >
          <Button>Dashboard</Button>
        </StyledLink>

        <StyledLink
          to="/"
          css={css`
            margin-top: 5px;
            margin-right: ${base * -23}px;
          `}
        >
          <AWLogo />
        </StyledLink>
        <UserContainer style={{ minWidth: '150px' }}>
          {!hideUser && (
            <>
              {user?.email && <ContactName email={user.email} />}
              {showSignIn && <SignOutButton />}
            </>
          )}
        </UserContainer>
      </StyledContent>
    </StyledHeader>
  )
}

const StyledHeader = styled.header`
  width: 100%;
  height: ${base * 18}px;
  padding: 0 ${gutter}px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--surface);
  & svg {
    color: var(--accent);
  }
`

const StyledContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

const UserContainer = styled.div`
  display: flex;
  align-items: center;
  & > h3 {
    margin-right: ${base * 2}px;
  }
`

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  color: var(--secondary-1000);
  font-weight: bold;

  > * {
    margin-right: 16px;
  }
`
