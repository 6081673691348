import { css } from '@emotion/react'
import styled from '@emotion/styled'
import Close from '@mui/icons-material/Close'
import { useRecoilState, useRecoilValue } from 'recoil'
import { modalContentState, modalOpenState } from '../state'
import { base } from '../styles/spacing'
import { IconButton } from './mui/IconButton'
import { Modal as StyledModal } from './mui/Modal'

export const Modal = () => {
  const [open, setOpen] = useRecoilState(modalOpenState)
  const { title, content, showCloseButton } = useRecoilValue(modalContentState)

  return (
    <StyledModal open={open} onClose={() => setOpen(false)}>
      <Content>
        {typeof title === 'string' ? (
          <h2
            className="h2"
            css={css`
              margin-bottom: ${base * 3}px;
            `}
          >
            {title}
          </h2>
        ) : (
          <>{title}</>
        )}
        {showCloseButton && (
          <IconButton
            sx={{
              position: 'absolute',
              right: `${base * 2}px`,
              top: `${base * 2}px`,
            }}
            onClick={() => setOpen(false)}
          >
            <Close />
          </IconButton>
        )}

        {content}
      </Content>
    </StyledModal>
  )
}

const Content = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--surface);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${base * 4}px;
  text-align: center;
  max-width: ${base * 120}px;
`
