import { SerializedStyles } from '@emotion/react'
import styled from '@emotion/styled'
import { base } from '../styles/spacing'
import CloseIcon from '@mui/icons-material/Close'

type ColorType = 'default' | 'bright' | 'dark' | 'outlined'

type TagProps = {
  small?: boolean
  rounded?: boolean
  color?: ColorType
  label: string
  styling?: SerializedStyles
  onRemove?: () => void
}

export const Tag = ({
  small = false,
  rounded = false,
  color = 'default',
  label,
  styling,
  onRemove,
}: TagProps) => (
  <StyledTag small={small} rounded={rounded} color={color} css={styling || ''}>
    <span>{label}</span>
    {onRemove && (
      <CloseIcon
        sx={{ fontSize: 14, marginLeft: `${base}px` }}
        onClick={onRemove}
      />
    )}
  </StyledTag>
)

const getBackgroundColor = (color: ColorType) => {
  switch (color) {
    case 'default':
      return 'var(--default-100)'
    case 'bright':
      return 'var(--accent-light)'
    case 'dark':
      return 'var(--primary-100)'
    case 'outlined':
      return 'var(--surface)'
  }
}

const getColor = (color: ColorType) => {
  switch (color) {
    case 'default':
      return 'var(--default-700)'
    case 'bright':
      return 'var(--secondary-1000)'
    case 'dark':
      return 'var(--primary-1000)'
    case 'outlined':
      return 'var(--secondary-1000)'
  }
}

const getBorder = (color: ColorType) => {
  switch (color) {
    case 'default':
      return '1px solid var(--default-100)'
    case 'bright':
      return '1px solid var(--accent-light)'
    case 'dark':
      return '1px solid var(--primary-100)'
    case 'outlined':
      return '1px solid var(--secondary-1000)'
  }
}

const StyledTag = styled.div<{
  small: boolean
  rounded: boolean
  color: ColorType
}>`
  font-size: ${({ small }) => (small ? `${base * 3}px` : `${base * 4}px`)};
  background-color: ${({ color }) => getBackgroundColor(color)};
  border-radius: ${({ rounded }) => (rounded ? `${base * 8}px` : '0')};
  padding: ${({ small }) =>
    small ? `${base}px ${base * 2}px` : `${base}px ${base * 3}px`};
  color: ${({ color }) => getColor(color)};
  border: ${({ color }) => getBorder(color)};
  line-height: 1;
  display: flex;
  align-items: flex-end;
  width: fit-content;

  span {
    display: inline-block;
    ::first-letter {
      text-transform: capitalize;
    }
  }
`
