import { useEffect } from 'react'
import { BFF_ENDPOINT } from '../config'

const stream = new EventSource(`${BFF_ENDPOINT}/streams/updates`)

//TODO: Remove when able to sync types
export type Watchlist = { type: 'collection' | 'id'; value: string }[]

export const useSSEWatch = (
  listener: (e: MessageEvent) => any,
  watchlist: Watchlist,
) => {
  useEffect(() => {
    const _listener = (e: any) => {
      const [collection, id] = e.data.split('|')
      for (const watchItem of watchlist) {
        if (
          (watchItem.type === 'collection' &&
            watchItem.value.includes(collection)) ||
          (watchItem.type === 'id' && watchItem.value.includes(id))
        ) {
          listener(e)
        }
      }
    }

    stream.addEventListener('update', _listener)

    return () => {
      stream.removeEventListener('update', _listener)
    }
  }, [listener, watchlist])
}
