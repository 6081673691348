import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Fragment } from 'react'
import { base, gutter } from '../styles/spacing'
import { Link } from './Link'

export const Footer = () => {
  return (
    <StyledFooter>
      <ContentContainer>
        <p
          css={css`
            color: var(--surface);
            font-size: 16px;
          `}
        >
          Copyright © 2021 AW
        </p>
        <p
          css={css`
            color: var(--surface);
            font-size: 16px;
            margin-top: ${base * 3}px;
          `}
        >
          Privacy policy in{' '}
          {privayPolicies.map(({ country, url }, i) => (
            <Fragment key={country}>
              {i !== 0 && ` | `}
              <Link
                href={url}
                targetBlank
                styling={css`
                  color: var(--surface);
                  font-size: 16px;
                `}
              >
                {country}
              </Link>
            </Fragment>
          ))}
        </p>
      </ContentContainer>
    </StyledFooter>
  )
}

const StyledFooter = styled.footer`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--secondary-1000);
  padding: ${gutter}px ${base * 4}px;
  color: var(--surface);
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const privayPolicies = [
  {
    country: 'CH',
    url: 'https://www.academicwork.ch/about/politique-de-confidentialite',
  },
  {
    country: 'DE',
    url: 'https://www.academicwork.de/ueber-uns/datenschutzhinweise',
  },
  {
    country: 'DK',
    url: 'https://www.academicwork.dk/om/privatpolitik',
  },
  {
    country: 'FI',
    url: 'https://www.academicwork.fi/tietoameista/tietosuojaseloste',
  },
  {
    country: 'NO',
    url: 'https://www.academicwork.no/om-oss/personvernerkl-ring',
  },
  {
    country: 'SE',
    url: 'https://www.academicwork.se/om-oss/privacy-policy',
  },
]
