export const AWLogo = () => {
  const height = 24
  return (
    <span aria-label="Company logo" style={{ height: `${height}px` }}>
      <svg viewBox="0 0 234 116.6" height={`${height}px`} fill="currentColor">
        <path
          d="M63.9,18.2l-25.5,76H74.3l2.4,7.2H36L24.8,134.7h7.7l8.7-25.9h38l8.7,25.9h7.7l-31.5-94,3.7-11L103,134.8h7.7L71.6,18.2Zm7.9,68.6H48.6L60.2,52.2Z"
          transform="translate(-24.8 -18.2)"
        />
        <polygon points="196.1 0 169.1 80.7 153.6 34.6 145.9 34.6 165.2 92.2 161.5 103.2 138.5 34.6 129.5 34.6 114 80.7 86.9 0 79.3 0 110.2 92.2 106.5 103.2 71.9 0 64.2 0 103.3 116.6 109.7 116.6 109.7 116.6 134 44.1 137.7 55.2 117.1 116.6 124.8 116.6 141.5 66.6 158.3 116.6 158.3 116.6 164.7 116.6 203.8 0 196.1 0" />
        <polygon points="211.2 0 172.1 116.6 179.8 116.6 218.9 0 211.2 0" />
        <polygon points="226.3 0 187.2 116.6 194.9 116.6 234 0 226.3 0" />
      </svg>
    </span>
  )
}
