import { SerializedStyles } from '@emotion/react'
import styled from '@emotion/styled'

type LinkProps = {
  href?: string
  targetBlank?: boolean
  children: React.ReactNode
  styling?: SerializedStyles
}

const getLinkProps = (targetBlank: boolean) => {
  if (targetBlank) {
    return { target: '_blank', rel: 'nooperner noreferrer' }
  }
  return { target: '_self' }
}

export const Link = ({
  href,
  targetBlank = false,
  children,
  styling,
}: LinkProps) => {
  if (!href) {
    return <>{children}</>
  }

  return (
    <StyledLink href={href} {...getLinkProps(targetBlank)} css={styling || ''}>
      {children}
    </StyledLink>
  )
}

const StyledLink = styled.a`
  font-size: 18px;
  color: var(--secondary-1000);
  &:hover {
    text-decoration: underline;
  }
`
