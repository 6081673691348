import { SerializedStyles } from '@emotion/react'
import styled from '@emotion/styled'
import PersonOutline from '@mui/icons-material/PersonOutline'
import { base } from '../styles/spacing'

type AvatarProps = {
  children?: React.ReactNode
  styling?: SerializedStyles
  size?: string
}

export const Avatar = ({ children, styling, size = '64px' }: AvatarProps) => (
  <StyledAvatar size={size} css={styling || ''}>
    {children ? children : <PersonOutline />}
  </StyledAvatar>
)

const StyledAvatar = styled.div<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  border-radius: 50%;
  background-color: var(--default-100);
  color: var(--primary-1000);
  font-size: ${base * 9}px;
  display: flex;
  justify-content: center;
  align-items: center;
  & svg {
    width: 75%;
    height: 75%;
  }
`
