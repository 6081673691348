import styled from '@emotion/styled'
import { ExpandMore } from '@mui/icons-material'
import { useRecoilValue } from 'recoil'
import { JobCard } from '../components/JobCard'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '../components/mui/Accordion'
import { CircularProgress } from '../components/mui/CircularProgress'
import { useReadmodel } from '../hooks/useReadmodel'
import { userState } from '../state'
import { base, gutter } from '../styles/spacing'

export const Home = () => {
  const user = useRecoilValue(userState)
  const [jobs, isLoading] = useReadmodel('getJobs', null, [])

  if (!user) {
    return <BlankPage />
  }

  if (isLoading) {
    return (
      <LoaderWrapper>
        <CircularProgress size={60} aria-label="Loading" />
      </LoaderWrapper>
    )
  }

  if (!jobs.length) {
    return (
      <NoJobContainer>
        <h2 className="h2">
          At the moment you have no ongoing jobs with Academic Work.
        </h2>
      </NoJobContainer>
    )
  }

  const isFilledDateWithinActivePeriod = (dateString: Date) => {
    const filledDate = new Date(dateString)

    return new Date() <= new Date(filledDate.setDate(filledDate.getDate() + 30))
  }

  return (
    <>
      <Accordion disableGutters defaultExpanded>
        <AccordionSummary
          style={{
            paddingLeft: base * 4,
            marginBottom: gutter,
            borderBottom: '1px solid var(--default-200)',
          }}
          expandIcon={<ExpandMore />}
        >
          Open jobs
        </AccordionSummary>
        <AccordionDetails style={{ padding: 0 }}>
          {jobs
            .filter((job) => !job.filled)
            .map((job, key) => (
              <JobCard job={job} key={key} />
            ))}
        </AccordionDetails>
      </Accordion>

      {jobs.some(
        (job) =>
          job.filledDate && isFilledDateWithinActivePeriod(job.filledDate),
      ) && (
        <Accordion disableGutters>
          <AccordionSummary
            style={{
              paddingLeft: base * 4,
              marginBottom: gutter,
              borderBottom: '1px solid var(--default-200)',
            }}
            expandIcon={<ExpandMore />}
          >
            Filled jobs
          </AccordionSummary>
          <AccordionDetails style={{ padding: 0 }}>
            {jobs
              .filter(
                (job) =>
                  job.filledDate &&
                  job.filled &&
                  isFilledDateWithinActivePeriod(job.filledDate),
              )
              .map((job, key) => (
                <JobCard job={job} key={key} />
              ))}
          </AccordionDetails>
        </Accordion>
      )}
    </>
  )
}

const BlankPage = styled.div`
  background-color: var(--surface);
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10000;
`

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
`

const NoJobContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${base * 20}px;
`
