import styled from '@emotion/styled'
import { useSetRecoilState } from 'recoil'
import { modalContentState } from '../state'
import { base } from '../styles/spacing'
import { deleteAllCookies } from '../utils/utils'
import { Confirm } from './Confirm'
import { Button } from './mui/Button'

type Props = {
  confirmSignOut?: boolean
}

const signOutUser = () => {
  deleteAllCookies()
  window.location.assign('/api/logout')
}

export const SignOutButton = ({ confirmSignOut = true }: Props) => {
  const setModalContent = useSetRecoilState(modalContentState)
  const openSignOutConfirmModal = () => {
    setModalContent({
      title: 'Are you sure you want to sign out?',
      content: <Confirm onConfirm={signOutUser} />,
    })
  }

  const handleSignOutClick = () => {
    if (confirmSignOut) {
      openSignOutConfirmModal()
    } else {
      signOutUser()
    }
  }

  return (
    <Button
      size="small"
      variant="contained"
      onClick={handleSignOutClick}
      sx={{ whiteSpace: 'nowrap' }}
    >
      Sign out
    </Button>
  )
}

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: ${base * 82}px;
`

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  & > button:first-of-type {
    margin-right: ${base}px;
  }
  & > button:last-child {
    margin-left: ${base}px;
  }
`
