import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { GetJobsResponse } from 'shared/readmodels/getJobs.schema'
import { Link } from 'react-router-dom'
import { base, gutter } from '../styles/spacing'
import { Card } from './Card'
import { Button } from './mui/Button'
import { Tag } from './Tag'

type Job = GetJobsResponse[0]

export const JobCard = ({ job }: { job: Job }) => {
  const calculateDaysLeft = (date: Date) => {
    const expirationDate = new Date(date.setDate(date.getDate() + 30))
    const timeDifference = expirationDate.getTime() - new Date().getTime()
    const daysLeft = Math.ceil(timeDifference / (1000 * 3600 * 24))
    return `remove in ${daysLeft} ${daysLeft === 1 ? 'day' : 'days'}`
  }

  return (
    <Card
      styling={css`
        margin-bottom: ${gutter}px;
      `}
    >
      <h2
        className="h2"
        css={css`
          color: var(--secondary-1000);
          margin-bottom: ${base * 4}px;
        `}
      >
        {job.title}
      </h2>
      <p>Job-ID: {job.adaptPublicId}</p>
      <ButtonContainer filled={job.filled}>
        {job.filled && job.filledDate && (
          <Tag
            label={calculateDaysLeft(new Date(job.filledDate))}
            color="bright"
          />
        )}

        {job.presentationCount && job.presentationCount > 0 ? (
          <Link to={`/job/${job._id}`}>
            <Button size="small" variant="contained" aria-hidden>
              See candidates
            </Button>
          </Link>
        ) : (
          <Button size="small" variant="contained" disabled>
            See candidates
          </Button>
        )}
      </ButtonContainer>
    </Card>
  )
}

const ButtonContainer = styled.div<{ filled?: boolean }>`
  display: flex;
  justify-content: ${({ filled }) => (filled ? `space-between` : `flex-end`)};
  margin-top: ${base * 4}px;
  align-items: center;
`
