import { css } from '@emotion/react'
import styled from '@emotion/styled'
import Close from '@mui/icons-material/Close'
import { FC } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { GetCollaboratorsResponse } from 'shared/readmodels/getCollaborators.schema'
import { GetJobByIdResponse } from 'shared/readmodels/getJobById.schema'
import { modalContentState, userState } from '../../state'
import { base } from '../../styles/spacing'
import { postCommand } from '../../utils/postCommand'
import { Alert } from '../Alert'
import { Confirm } from '../Confirm'
import { IconButton } from '../mui/IconButton'
import { Tag } from '../Tag'
import { Collaborators, InviteCollaboratorsHeader } from './Collaborators'

type CollaboratorListProps = {
  collaborators: GetCollaboratorsResponse
  mainContactId: number
  jobId: NonNullable<GetJobByIdResponse>['_id']
}

export type ConfirmationCollaborator = {
  id: string
  name: string
  email: string
  status?: GetCollaboratorsResponse[0]['status']
}

export const CollaboratorList: FC<CollaboratorListProps> = ({
  collaborators,
  mainContactId,
  jobId,
}) => {
  const user = useRecoilValue(userState)
  const setModalContent = useSetRecoilState(modalContentState)

  const removeCollaborator = postCommand('removeCollaborator')

  const handleRemove = (collaborator: ConfirmationCollaborator) => {
    setModalContent({
      title: 'Are you sure?',
      content: (
        <Confirm
          subText={`You will remove ${collaborator.name} (${collaborator.email}) from accessing this job.`}
          onConfirm={() =>
            removeCollaborator.post({
              jobId,
              collaboratorId: collaborator.id,
              isPendingCollaborator: Boolean(collaborator.status),
            })
          }
          onCancel={() =>
            setModalContent({
              title: <InviteCollaboratorsHeader />,
              content: (
                <Collaborators jobId={jobId} mainContactId={mainContactId} />
              ),
              showCloseButton: true,
            })
          }
        />
      ),
    })
  }

  return (
    <List>
      {collaborators &&
        collaborators.map(
          ({ _id, firstName, lastName, email, status, isMainContact }) => (
            <ListItem key={_id}>
              <Collaborator>
                {
                  <p
                    css={css`
                      font-weight: ${isMainContact ? '700' : 'inherit'};
                    `}
                  >{`${firstName} ${lastName} (${email})`}</p>
                }
                {!isMainContact && mainContactId === Number(user?.personId) && (
                  <IconButton
                    disabled={status === 'pending'}
                    onClick={() => {
                      handleRemove({
                        id: _id,
                        name: `${firstName} ${lastName}`,
                        email,
                        status,
                      })
                    }}
                  >
                    <Close />
                  </IconButton>
                )}
              </Collaborator>
              {status === 'needsApproval' && (
                <Alert
                  severity="info"
                  styling={css`
                    margin-top: ${base}px;
                  `}
                  message="This collaborator needs to be approved by your recruiter. If you have any questions, please contact your recruiter."
                />
              )}
              {status === 'pending' && (
                <Tag
                  styling={css`
                    margin-top: ${base}px;
                  `}
                  small
                  rounded
                  label="Pending"
                />
              )}
            </ListItem>
          ),
        )}
    </List>
  )
}

const List = styled.ul`
  padding-inline-start: 0px;
  list-style-type: none;
`

const ListItem = styled.li`
  display: flex;
  flex-wrap: wrap;
  padding: ${base * 2}px 0px;
  &:not(:last-of-type) {
    border-bottom: 1px solid var(--default-200);
  }
`

const Collaborator = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
