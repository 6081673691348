import axios from 'axios'
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { RecoilRoot } from 'recoil'
import { App } from './src/App'

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status === 401) {
      const currentPath = window.location.pathname + window.location.search
      window.location.href = `/api/login?current=${encodeURIComponent(
        currentPath,
      )}`
    }
    return Promise.reject(error)
  },
)

axios.interceptors.request.use(
  (config) => {
    const body = document.querySelector('body')
    const version = body?.dataset.version
    if (version && config.headers) {
      config.headers['X-App-Version'] = version
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  },
)

ReactDOM.render(
  <RecoilRoot>
    <Router>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </Router>
  </RecoilRoot>,

  document.getElementById('root'),
)
