import styled from '@emotion/styled'
import { Route, Routes } from 'react-router-dom'
import { Footer } from './components/Footer'
import { Header } from './components/Header'
import { Modal } from './components/Modal'
import { Snackbar } from './components/Snackbar'
import './styles/index.css'
import { base, gutter, lg, maxPageWidth } from './styles/spacing'
import './styles/typography.css'
import './styles/customProperties.css'
import { Error } from './views/Error'
import { Home } from './views/Home'
import { JobView } from './views/JobView'
import { MaintenacePage } from './MaintenacePage'

const showMaintenancePage = false

export const App = () => {
  if (showMaintenancePage) {
    return <MaintenacePage />
  }
  return (
    <AppWrapper>
      <Header />
      <Routes>
        <Route
          path="/error"
          element={
            <ContentArea>
              <Error />
            </ContentArea>
          }
        ></Route>
        <Route
          path="/"
          element={
            <ContentArea>
              <Home />
            </ContentArea>
          }
        ></Route>
        <Route path="/job/:id" element={<JobView />}></Route>
      </Routes>
      <Footer />
      <Modal />
      <Snackbar />
    </AppWrapper>
  )
}

export const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 0;
  min-height: 100vh;
  background-color: var(--background);
`

export const ContentArea = styled.main`
  width: 100%;
  max-width: ${maxPageWidth}px;
  flex-grow: 1;
  padding: ${gutter}px ${base * 5}px;
  margin: 0 auto;
  @media (min-width: ${lg}px) {
    padding: ${gutter}px 0px;
  }
`
