import styled from '@emotion/styled'
import InfoOutlined from '@mui/icons-material/InfoOutlined'
import CheckCircleOutlined from '@mui/icons-material/CheckCircleOutlined'
import WarningAmberOutlined from '@mui/icons-material/WarningAmberOutlined'
import ErrorOutlineOutlined from '@mui/icons-material/ErrorOutlineOutlined'
import { base } from '../styles/spacing'
import { IconButton } from './mui/IconButton'
import CloseOutlined from '@mui/icons-material/CloseOutlined'
import { SerializedStyles } from '@emotion/react'
import { forwardRef } from 'react'

type SeverityType = 'info' | 'success' | 'error' | 'warning'

type AlertProps = {
  severity?: SeverityType
  message: string
  onClose?: () => void
  styling?: SerializedStyles
}

export const Alert = forwardRef<HTMLDivElement, AlertProps>(
  ({ message, severity = 'info', onClose, styling }, ref) => {
    return (
      <StyledAlert severity={severity} css={styling || ''} ref={ref}>
        {severityStyling[severity].icon}
        <p>{message}</p>
        {onClose && (
          <IconButton onClick={onClose}>
            <CloseOutlined />
          </IconButton>
        )}
      </StyledAlert>
    )
  },
)

const severityStyling = {
  info: {
    fill: 'var(--info-color)',
    background: 'var(--info-background)',
    icon: <InfoOutlined />,
  },
  success: {
    fill: 'var(--success-color)',
    background: 'var(--success-background)',
    icon: <CheckCircleOutlined />,
  },
  warning: {
    fill: 'var(--warning-color)',
    background: 'var(--warning-background)',
    icon: <WarningAmberOutlined />,
  },
  error: {
    fill: 'var(--error-color)',
    background: 'var(--error-background)',
    icon: <ErrorOutlineOutlined />,
  },
}

const StyledAlert = styled.div<{ severity: SeverityType }>`
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-column-gap: ${base * 3}px;
  align-items: start;
  width: 100%;
  padding: ${base * 3}px ${base * 4}px;
  background-color: ${({ severity }) => severityStyling[severity].background};
  & > svg {
    fill: ${({ severity }) => severityStyling[severity].fill};
    width: 20px;
    height: 20px;
    margin-top: 5px;
  }
  & > button {
    padding: ${base}px;
    height: 32px;
    width: 32px;
  }
`
