import axios, { AxiosResponse } from 'axios'
import { useSetRecoilState } from 'recoil'
import { Commands, commandsSchema } from 'shared/commands/commands.schema'
import { z, ZodError } from 'zod'
import { BFF_ENDPOINT } from '../config'
import { snackbarMessageState } from '../state'

const successMessages = {
  addNote: 'Message sent',
  deleteNote: 'Message deleted',
  inviteCollaborator: 'Collaborator invited',
  removeCollaborator: 'Collaborator removed',
  setNotesAsRead: undefined,
  setPresentationAsInteresting: 'Candidate set as interesting',
  setPresentationAsNotInteresting: 'Candidate set as not interesting',
  setPresentationAsViewed: undefined,
  clearPresentationNotification: undefined,
  addTag: undefined,
  deleteTag: undefined,
}

export const postCommand = <
  T extends keyof typeof commandsSchema & keyof Commands,
>(
  commandName: T,
): {
  validate: (
    value: Commands[T],
  ) =>
    | { success: true; data: z.infer<typeof commandsSchema[T]> }
    | { success: false; error: ZodError }
  isValid: (value: Commands[T]) => boolean
  post: (value: Commands[T]) => Promise<AxiosResponse<any>>
} => {
  const setSnackbarValue = useSetRecoilState(snackbarMessageState)

  const validate = (value: Commands[T]) =>
    commandsSchema[commandName].safeParse(value)
  const isValid = (value: Commands[T]) =>
    commandsSchema[commandName].safeParse(value).success
  const post = async (value: Commands[T]) => {
    return await axios
      .post(
        `${BFF_ENDPOINT}/command/${commandName}`,
        commandsSchema[commandName].parse(value),
      )
      .then((res) => {
        if (successMessages[commandName]) {
          setSnackbarValue({
            message: successMessages[commandName] || 'Success',
            severity: 'success',
          })
        }
        return res
      })
      .catch((err) => {
        setSnackbarValue({
          message: `Error: ${err.message}`,
          severity: 'error',
        })
        return err
      })
  }
  return { validate, isValid, post }
}
