import { SerializedStyles } from '@emotion/react'
import styled from '@emotion/styled'
import { base } from '../styles/spacing'

type CardProps = {
  children?: React.ReactNode
  styling?: SerializedStyles
  onClick?: () => void
  outlined?: boolean
}

export const Card = ({
  children,
  styling,
  onClick,
  outlined = false,
}: CardProps) => {
  return (
    <StyledDiv css={styling || ''} onClick={onClick} outlined={outlined}>
      {children}
    </StyledDiv>
  )
}

const StyledDiv = styled.div<{ outlined: boolean }>`
  width: 100%;
  background-color: var(--surface);
  padding: ${base * 4}px;
  word-break: break-word;
  border: ${({ outlined }) =>
    outlined ? '1px solid var(--default-200)' : 'none'};
`
