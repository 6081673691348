import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { GetJobByIdResponse } from 'shared/readmodels/getJobById.schema'
import { base, gutter } from '../styles/spacing'
import { Card } from './Card'
import { Button } from './mui/Button'
import FilterListIcon from '@mui/icons-material/FilterList'

type JobHeaderProps = {
  onlyShowDetails: boolean
  job: GetJobByIdResponse
  handleInviteCollaborators: () => void
  onFilterClick?: () => void
}

export const JobHeader = ({
  onlyShowDetails,
  job,
  handleInviteCollaborators,
  onFilterClick,
}: JobHeaderProps) => {
  return (
    <Card
      styling={css`
        display: ${onlyShowDetails ? 'none' : 'block'};
        visibility: ${onlyShowDetails ? 'hidden' : 'visible'};
        margin-bottom: ${gutter}px;
      `}
    >
      <HeadingContainer>
        <h2
          className="h2"
          css={css`
            color: var(--secondary-1000);
          `}
        >
          {job?.title}
        </h2>
        <Button variant="text" size="small" onClick={handleInviteCollaborators}>
          Invite collaborators
        </Button>
      </HeadingContainer>
      <div
        css={css`
          display: flex;
          justify-content: space-between;
          align-items: center;
        `}
      >
        <RecruiterInfoContainer>
          <h4 className="h4">Recruiter</h4>
          <h4 className="h4">Phone</h4>
          <h4 className="h4">E-mail</h4>
          <p>{job?.cmName}</p>
          <p>{job?.cmPhone || '-'}</p>
          <p>{job?.cmEmail}</p>
        </RecruiterInfoContainer>
        {onFilterClick && (
          <Button
            onClick={() => {
              onFilterClick()
            }}
          >
            <FilterListIcon sx={{ marginRight: `${base * 2}px` }} /> Filter
          </Button>
        )}
      </div>
    </Card>
  )
}

const HeadingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${gutter}px;
`

const RecruiterInfoContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-column-gap: ${base * 8}px;
  justify-content: start;
`
