import { Snackbar as StyledSnackbar } from './mui/Snackbar'
import { useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'
import { snackbarMessageState } from '../state'
import { Alert } from './Alert'

export const Snackbar = () => {
  const [open, setOpen] = useState(false)
  const [{ message, severity }, setSnackbarValue] =
    useRecoilState(snackbarMessageState)

  useEffect(() => {
    if (message) {
      setOpen(true)
    }
  }, [message])

  const handleClose = () => {
    setSnackbarValue({ message: null })
    setOpen(false)
  }

  return (
    <StyledSnackbar
      open={open}
      onClose={handleClose}
      autoHideDuration={3000}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    >
      <Alert
        onClose={handleClose}
        severity={severity || 'info'}
        message={message || ''}
      />
    </StyledSnackbar>
  )
}
