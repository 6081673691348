import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { useEffect, useState } from 'react'
import { Board } from 'shared/collections/board.schema'
import { GetJobByIdResponse } from 'shared/readmodels/getJobById.schema'
import { PresentationCard } from './PresentationCard'
import { PresentationDetails } from './PresentationDetails'
import { useReadmodel } from '../hooks/useReadmodel'
import { base } from '../styles/spacing'
import { GetNotificationsResponse } from 'shared/readmodels/getNotifications.schema'
import { RightDrawer } from './RightDrawer'
import { FilterMenuCard } from './FilterMenu'
import { useSearchParams } from 'react-router-dom'

type Job = NonNullable<GetJobByIdResponse>

type Presentation = Job['presentations'][0]

type PresentationBoardProps = {
  job: Job
  notificationPresentationIds: GetNotificationsResponse
  filterOpen: Boolean
  tags: string[]
  filteredTags: string[]
  onFilterUpdate: (tags: string[]) => void
}

type Lane = {
  _id: string
  index: number
  title: string
  type: Board['lanes'][0]['type']
  presentations: Presentation[]
}

export const PresentationBoard = ({
  job,
  notificationPresentationIds,
  filterOpen,
  tags,
  filteredTags,
  onFilterUpdate,
}: PresentationBoardProps) => {
  const [board] = useReadmodel('getBoardByJobId', { jobId: job._id }, null)
  const [lanes, setLanes] = useState<Lane[]>()
  const [selectedPresentationId, setSelectedPresentationId] = useState<
    Presentation['_id'] | null
  >(null)
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    const presentationId = searchParams.get('presentationId')
    const presentation = job.presentations.find((x) => x._id === presentationId)
    if (presentationId && presentation) {
      setSelectedPresentationId(presentationId)
    }
  })

  useEffect(() => {
    const _lanes = board?.lanes.map(({ _id, title, type, items }, index) => {
      const presentations = job.presentations.filter((x) =>
        items.map((i) => i.presentationId).includes(x._id),
      )
      return {
        _id,
        index,
        title,
        type,
        presentations,
      } as Lane
    })
    setLanes(_lanes)
  }, [job.presentations, board])

  return (
    <div
      css={css`
        overflow: auto;
        max-height: calc(100vh - ${base * 100}px);
        background-color: var(--background);
      `}
    >
      {filterOpen && (
        <FilterMenuCard
          tags={tags}
          onFilterUpdate={onFilterUpdate}
          filteredTags={filteredTags}
          simpleView={false}
        />
      )}
      <div
        css={css`
          height: 100%;
          display: flex;
        `}
      >
        {lanes
          ?.filter((x) => x.type === 'public')
          .map((lane, ind) => (
            <StyledLane title={lane.title} key={lane._id}>
              <StyledLaneHeaderContainer key={`${lane._id}-${lane.title}`}>
                <h2
                  css={css`
                    color: var(--surface);
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                  `}
                >
                  {lane.title}
                </h2>
                <span>{lane.presentations.length}</span>
              </StyledLaneHeaderContainer>
              <StyledPresentationsContainer key={lane._id}>
                {lane.presentations
                  .filter((presentation) =>
                    filteredTags.length
                      ? filteredTags.some((tag) =>
                          presentation.tags?.includes(tag),
                        )
                      : true,
                  )
                  .map((p) => (
                    <PresentationCardWrapper key={`${p._id}-${p.name}`}>
                      <PresentationCard
                        hasNotification={notificationPresentationIds.includes(
                          p._id,
                        )}
                        isSelected={false}
                        jobType={job.jobType}
                        presentation={p}
                        key={p._id}
                        onClickHandler={() => {
                          setSelectedPresentationId(p._id)
                          setSearchParams((params) => {
                            params.set('presentationId', p._id)
                            return params
                          })
                        }}
                      />
                    </PresentationCardWrapper>
                  ))}
              </StyledPresentationsContainer>
            </StyledLane>
          ))}
      </div>
      <RightDrawer
        open={Boolean(selectedPresentationId)}
        setOpen={() => setSelectedPresentationId(null)}
        onClose={() => {
          setSearchParams((params) => {
            params.delete('presentationId')
            return params
          })
        }}
      >
        <PresentationDetails
          cmName={job.cmName}
          jobType={job.jobType}
          presentation={job.presentations.find(
            (p) => p._id === selectedPresentationId,
          )}
        />
      </RightDrawer>
    </div>
  )
}

const PresentationCardWrapper = styled.div`
  box-shadow: var(--elevation-1);
`

const StyledLane = styled.div`
  margin-right: ${base * 4}px;
  min-width: ${base * 64}px;
  max-width: ${base * 64}px;
`

const StyledLaneHeaderContainer = styled.div`
  padding: ${base * 2}px;
  background: var(--secondary-1000);
  position: sticky;
  z-index: 2;
  top: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  > * {
    color: var(--surface);
  }
`

const StyledPresentationsContainer = styled.div`
  margin-top: ${base * 4}px;
`
