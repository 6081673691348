import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { base } from '../styles/spacing'
import { Card } from './Card'
import { Accordion, AccordionDetails, AccordionSummary } from './mui/Accordion'
import { ExpandMore } from '@mui/icons-material'
import { Tag } from './Tag'
import { Badge } from './mui/Badge'

type FilterMenuProps = {
  tags: string[]
  filteredTags: string[]
  onFilterUpdate: (tags: string[]) => void
  simpleView: boolean
}

export const FilterMenuCard = ({
  tags,
  filteredTags,
  onFilterUpdate,
  simpleView,
}: FilterMenuProps) => {
  if (simpleView) {
    return (
      <Card
        styling={css`
          padding: 0;
          margin-bottom: ${base * 4}px;
          position: relative;
        `}
      >
        <Accordion disableGutters>
          <AccordionSummary
            style={{ paddingLeft: base * 4, margin: 0 }}
            expandIcon={<ExpandMore />}
          >
            <Badge badgeContent={filteredTags.length} color="primary">
              <h3
                css={css`
                  color: var(--secondary-1000);
                  margin: 0;
                `}
              >
                Filter
              </h3>
            </Badge>
          </AccordionSummary>
          <AccordionDetails style={{ padding: 0, flexWrap: 'wrap' }}>
            <CardContent>
              <h4
                css={css`
                  margin-bottom: ${base * 2}px;
                `}
              >
                Labels
              </h4>
              <TagList horizontal={true}>
                {tags.map((t) => (
                  <li key={t}>
                    <TagButton
                      onClick={() => {
                        let newTags = []
                        if (filteredTags.includes(t)) {
                          newTags = filteredTags.filter((tag) => tag !== t)
                        } else {
                          newTags = [...filteredTags, t]
                        }
                        onFilterUpdate(newTags)
                      }}
                    >
                      <Tag
                        color={filteredTags.includes(t) ? 'bright' : 'outlined'}
                        label={t}
                      />
                    </TagButton>
                  </li>
                ))}
              </TagList>
            </CardContent>
          </AccordionDetails>
        </Accordion>
      </Card>
    )
  }

  return (
    <Card
      styling={css`
        padding: 0;
        margin-bottom: ${base * 4}px;
        position: relative;
      `}
    >
      <CardContent
        css={css`
          display: flex;
          padding: ${base * 3}px ${base * 3}px ${base}px ${base * 3}px;
        `}
      >
        <Badge badgeContent={filteredTags.length} color="primary">
          <h3
            css={css`
              color: var(--secondary-1000);
              margin: 0;
            `}
          >
            Filter on labels
          </h3>
        </Badge>
        <TagList horizontal={true} boardView={true}>
          {tags.map((t) => (
            <li key={t}>
              <TagButton
                css={css`
                  border-radius: 20px;
                `}
                onClick={() => {
                  let newTags = []
                  if (filteredTags.includes(t)) {
                    newTags = filteredTags.filter((tag) => tag !== t)
                  } else {
                    newTags = [...filteredTags, t]
                  }
                  onFilterUpdate(newTags)
                }}
              >
                <Tag
                  color={filteredTags.includes(t) ? 'bright' : 'outlined'}
                  label={t}
                />
              </TagButton>
            </li>
          ))}
        </TagList>
      </CardContent>
    </Card>
  )
}
const CardContent = styled.div`
  width: 100%;
  border-width: 1px;
  padding: ${base * 4}px;
  padding-top: 0;
`
const TagList = styled.ul<{ horizontal: boolean; boardView?: boolean }>`
  list-style: none;
  margin: 0;
  padding: ${({ boardView }) => (boardView ? `0px 0px 0px ${base * 12}px` : 0)};

  ${({ horizontal }) =>
    horizontal &&
    css`
      display: flex;
      gap: ${base * 2}px;
    `}

  li:not(:last-child) {
    margin-bottom: ${base * 2}px;
  }
`
const TagButton = styled.button`
  background: 0;
  border: 0;
  cursor: pointer;
`
