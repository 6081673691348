import { addNoteSchema, AddNote } from './addNote.schema'
import { addTagSchema, AddTag } from './addTag.schema'
import { clearPresentationNotificationSchema, ClearPresentationNotification } from './clearPresentationNotification.schema'
import { deleteNoteSchema, DeleteNote } from './deleteNote.schema'
import { deleteTagSchema, DeleteTag } from './deleteTag.schema'
import { inviteCollaboratorSchema, InviteCollaborator } from './inviteCollaborator.schema'
import { removeCollaboratorSchema, RemoveCollaborator } from './removeCollaborator.schema'
import { setNotesAsReadSchema, SetNotesAsRead } from './setNotesAsRead.schema'
import { setPresentationAsInterestingSchema, SetPresentationAsInteresting } from './setPresentationAsInteresting.schema'
import { setPresentationAsNotInterestingSchema, SetPresentationAsNotInteresting } from './setPresentationAsNotInteresting.schema'
import { setPresentationAsViewedSchema, SetPresentationAsViewed } from './setPresentationAsViewed.schema'

export const commandsSchema = {
  addNote: addNoteSchema,
  addTag: addTagSchema,
  clearPresentationNotification: clearPresentationNotificationSchema,
  deleteNote: deleteNoteSchema,
  deleteTag: deleteTagSchema,
  inviteCollaborator: inviteCollaboratorSchema,
  removeCollaborator: removeCollaboratorSchema,
  setNotesAsRead: setNotesAsReadSchema,
  setPresentationAsInteresting: setPresentationAsInterestingSchema,
  setPresentationAsNotInteresting: setPresentationAsNotInterestingSchema,
  setPresentationAsViewed: setPresentationAsViewedSchema,
}

export type Commands = {
  addNote: AddNote,
  addTag: AddTag,
  clearPresentationNotification: ClearPresentationNotification,
  deleteNote: DeleteNote,
  deleteTag: DeleteTag,
  inviteCollaborator: InviteCollaborator,
  removeCollaborator: RemoveCollaborator,
  setNotesAsRead: SetNotesAsRead,
  setPresentationAsInteresting: SetPresentationAsInteresting,
  setPresentationAsNotInteresting: SetPresentationAsNotInteresting,
  setPresentationAsViewed: SetPresentationAsViewed,
}